@use "../../../assets/sass/variables" as variables;


.source-container {
  margin-top: 20px; 
  display: flex;
  flex-direction: column;
}

.source-list {
  padding-left: 18px;
  display:grid;
  grid-template-columns: repeat(2,1fr);
  font-family: "Font-Semibold";

  li {
    list-style-type: disc;
    margin: 2px 0;
    padding-right: 20px;
    font-size: 12px;
    
    &.active {
      color: variables.$linkColor;
    }
  }

  &.gp-x-10 {
    column-gap: 10px;
  }
}

.source-content {
  padding: 10px;     
  background-color: #fff;
  flex-grow: 1;
  display: flex;
  justify-content: center;

  a {
    font-family: font-bold, sans-serif;
  }
  .bg-yellow {
    background-color: yellow;
  }
}

.sources-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  padding: 10px 0;
  width: 100%;
}