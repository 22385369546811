.create-project-form-container {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    &-panel{
        font-size: 16px;
        font-weight: 600;
        line-height: 19.36px;
        text-align: left;
    }
    label {
        margin-bottom: 10px;
    }    
    .form-item {
        display: flex;
        flex-direction: column;
        gap:15px;
        margin-bottom: 15px;
    }

    .form-button {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
    }

    .ant-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .ant-form-item-label {
            width: fit-content;
        }
    }
    .inline-form-items {
        display: flex;
        justify-content: space-between;
    
        .form-item {
            width: 48%;
        }
    } 
    .error-message {
        color: red;
        font-size: 12px;
        margin-top: 5px;
    }
    .checkbox{
        background-color: var(bgGradient);
        &:hover {
                background-color: white;
                color: var(--bgGradient);
            }
    }
    .create-form-button{
        width: 70%;
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }   
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    // background: #F7F7F9;
    // font-size: medium;   
    border: 1px solid #E8E8E8;
    .ant-collapse-expand-icon{
        align-items: end;
    }
}

.ant-collapse>.ant-collapse-item {
    border-bottom: 0.3px solid #d9d9d9;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    background: #F7F7F9;
    padding: 16px 16px;
}