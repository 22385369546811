@import "../../assets/sass/variables";

.patientData {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-info {
    font-weight: 500;
    font-size: 18px;
    padding: 10px 0px;
  }

  &-patientInfo {
    // flex-grow: 1;
    background: #FFFFFF;
    padding: 0px 20px;
    margin-bottom: 50px;
    overflow: auto;
    transition: all 800ms ease;

    &-short {
      min-height: 50px;
      overflow: hidden;
      max-height: 50px;
    }

    &-expanded {
      overflow: auto;
      min-height: auto;
      max-height: 45vh;
      min-height: 18vh;
    }

  }

  .show-full {
    height: fit-content;
    overflow: auto;
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 30px;
    right: 0;
    padding: 12px 18px;
    width: 57%;

    .actions {
      display: flex;
      gap: 10px;
    }
  }

  &-patientSummary {
    background: #FFFFFF;
    padding: 0px 20px 20px;
    margin-bottom: 20px;
    overflow: auto;
    white-space: pre-line;
    flex-grow: 1;
  }

  &-downicon {
    font-size: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    span {
      position: absolute;
      bottom: 40px;
      color: $textSecondaryColor;
      background-color: $white;
      border-radius: 50%;
    }
  }

  &-upicon {
    font-size: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    span {
      position: absolute;
      bottom: 40px;
      color: $textSecondaryColor;
      background-color: $white;
      border-radius: 50%;
    }
  }

  &-footer {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

    .actions {
      display: flex;
      gap: 10px;
      font-size: 18px;
      color: $white;

      .action {
        border-radius: 50%;
        background-color: $darkBlue;
        padding: 8px;
        transition: all 300ms ease;
        cursor: pointer;

        &:hover {
          background-color: $white;
          color: $darkBlue
        }
      }
    }
  }
}

.modal {
  &-title {
    padding: 15px 30px;
    border-bottom: 1px solid $borderColor;
    border-radius: 3px 3px 0px 0px;
    background: $btnGradient;

    p {
      margin: 10px 0 0;
    }

    h1 {
      color: $white;
      font-size: 14px;
    }
  }

  &-body {
    padding: 20px 30px;
  }

  &-content {
    display: grid;
    justify-content: space-around;
    margin-top: 20px;
    grid-template-columns: 1fr 1fr;

    &-heading {
      padding-bottom: 5px;
      border-bottom: 1px solid $textSecondaryColor;
      margin-bottom: 10px;
      font-size: 16px;
    }

  }

  &-footer {
    width: 100%;
    display: flex;
    justify-content: end;

    .actions {
      display: flex;
      gap: 20px;
      font-size: 16px;
    }
  }

  &-radio {
    padding: 5px;
    display: flex;
    gap: 10px;
    cursor: pointer;
  }
}

.export-dropdown-item {
  &:hover{
    background-color: $darkBlue !important; 
    color: white !important;
  }  
}

.citation-wrapper {
  display: flex;

  .citation-markdown {
    display: inline;
  }

  .citation-number {
    color: $linkColor;
    cursor: pointer;
    font-weight: 600;
    margin-right: 2px;
    margin-left: 3px;
    white-space: nowrap;
    display: inline;
  
    &:hover {
      color: $likHoverColor;
    }
  }
}


.citation-markdown {
  display: inline;
  white-space: wrap;

  p {
    margin-bottom: 10px;
  }

  li {
    list-style-type: disc;
    color: black;
    margin-left: 20px;
    white-space: wrap;
    margin-bottom: 5px;
  }

  ul {
    margin-bottom: 20px;
  }
}

.citation-number {
  color: $linkColor;
  cursor: pointer;
  font-weight: 600;
  margin-right: 2px;
  margin-left: 3px;

  &:hover {
    color: $likHoverColor;
  }
}

.radio-wrapper {
  display: flex;
  align-items: center;
}