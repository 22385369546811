.response-letter-container {
  flex-grow: 1;
  background: #FFFFFF;
  padding: 20px;
  font-size: 16px;
  overflow: auto;
  height: 90%;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content {
  gap: 20px;
  display: flex;
}

.approve {
  padding-top: 20px;
  display: flex;
  justify-content: end;
}