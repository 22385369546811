@use "../../assets/sass/variables";



.project-container {
  height: 100vh;
  padding: 20px;

  .project-card {
    &-heading {
      font-family: "Font-Semibold", Arial, Sans-Serif;
      font-size: 16px;
      color: variables.$blackColor;
    }
    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }
    &-title {
      font-family: "Font-Semibold", Arial, Sans-Serif;
      font-size: 14px;
      color: variables.$linkColor;
    }
  }

  .view-control {
    opacity: 0.2;

    &.active {
      color: var(--linkColor);
      opacity: 1;
    }
  }
  .highlight {
    font-size: 12px;
    font-weight: bold;
  }
  
  .wrapper {
    max-height: calc(100vh - 235px);
  }
  
  .left-wrapper {
    padding: 0px 1.25rem !important;
  }
  
  .sort-Project {
    background-color: white;
    height: 40px;
  }
  
  .editDelete {
    display: flex;
    align-items: center;
  }
  
  .project-list {
    .ant-table .ant-table-thead > tr > th,
    .ant-table .ant-table-tbody > tr > td {
      border-right: none;
      text-align: left !important;
      &:last-child {
        width: 150px;
      }
    }
    .ant-table-container {
      width: 100%;
      table {
        width: 100%;
      }
    }
    .ant-table-tbody {
      tr {
        td:nth-child(1) {
          color: var(--linkColor);
          font-family: Font-Medium;
        }
      }
    }
  }
  
  .table-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .table-container .file-table {
    flex: 1;
    overflow-y: auto;
  }
  .see-more{
    font-size: small;
    font-weight: 100;
    opacity: .7;
    display: flex;
    justify-content: end;
    align-items: end;
    gap: 10px;
  }
  
  .custom-tooltip .ant-tooltip-inner {
    background-color: white !important;
    color: black !important;
  }
  
  .custom-tooltip .ant-tooltip-arrow {
    border-top-color: white !important;
  }
  
  .projects-not-found{
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: center;
    .text{
      font-size: large;
      font-weight: 500;
    }
  }

}

