@import "../../assets/sass/variables";

.project-details {
  width: 100%;
  display: flex;
  gap: 20px;
  position: relative;
  background: rgba(0, 0, 0, 0.02);
  border: 0.1em solid #d9d9d9;
  border-radius: 3px;
  transition: border-color 0.3s;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  &-title {
    font-weight: 600;
  }

  &-colomn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    justify-content: start;
    width: 47%;
  }

  &-divider {
    width: 1px;
    background-color: #d9d9d9;
    margin: 0 10px; /* Adds space around the divider */
  }
}
