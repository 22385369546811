@use "../../assets/sass/variables";

.e {
  &-pdf {
    &.e-content {
      padding: 0px 1.625rem 1.625rem 1.625rem;
      border-left: 1px solid variables.$borderColor;
      border-radius: 6px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    canvas {
      margin: 0 auto;
    }

    &-pagination {
      margin: 1.125rem 0;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.125rem;
      gap: 1.75rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .file-name {
        display: flex;
        justify-content: end;
        font-size: 12px;
        padding: 0 10px;
        text-align: center;
        flex-grow: 1; 
      }

      .pagination-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .expand-icon {
        font-size: 16px;
        justify-self: flex-end;
        margin-left: 10px;
        cursor: pointer;
        &:hover {
          color: variables.$darkBlue;
        }
      }

      &-btn {
        &.ant-btn {
          border: 1px solid #888c8f;
          border-radius: 16px;
          min-width: 84px;
          background-color: transparent;
          color: variables.$baseColor;

          &[disabled] {
            background-color: transparent;
            color: rgba(255, 255, 255, 0.25);
          }
        }
      }
    }
  }
}

     
